import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Теннисные корты Aventurinsky
			</title>
			<meta name={"description"} content={"Где тренируются чемпионы"} />
			<meta property={"og:title"} content={"Главная | Теннисные корты Aventurinsky"} />
			<meta property={"og:description"} content={"Где тренируются чемпионы"} />
			<meta property={"og:image"} content={"https://aventurinsky.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aventurinsky.com/img/940623464346.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aventurinsky.com/img/940623464346.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aventurinsky.com/img/940623464346.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Теннисные корты 
					<br />
					Aventurinsky
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
					max-width="900px"
				>
					Мы рады видеть вас здесь и не можем дождаться, чтобы показать вам, почему наш центр - лучший выбор для вашего тенниса. Независимо от того, являетесь ли вы опытным профессионалом или только начинаете, наши корты и удобства созданы для того, чтобы вы получили максимальное удовольствие от игры в теннис.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://aventurinsky.com/img/1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Теннисные корты
					</Strong>
				</Text>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline3"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Откройте для себя преимущества Aventurinsky
				</Text>
				<Text
					as="p"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					В Aventurinsky Tennis Courts мы верим в силу спорта, способного объединять людей и пропагандировать здоровый, активный образ жизни. Наши современные теннисные корты поддерживаются в соответствии с самыми высокими стандартами, обеспечивая идеальное игровое покрытие для всех наших гостей. Мы предлагаем гостеприимную атмосферу, где игроки любого уровня могут наслаждаться игрой, совершенствовать свои навыки и создавать неизгладимые воспоминания.
				</Text>
			</Box>
			<Box width="60%">
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Почему выбирают нас?
				</Text>
				<Box
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="48px 32px"
					lg-grid-gap="48px"
					lg-width="100%"
					sm-grid-template-columns="1fr"
					md-grid-gap="36px "
				>
					<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
						<Icon
							display="block"
							category="md"
							icon={MdDone}
							size="20px"
							color="--primary"
							margin="2px 16px 0px 0px"
						/>
						<Box display="flex" flex-direction="column">
							<Text
								as="h1"
								margin="0px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
							>
								<Strong>
								Корты высшего качества
								</Strong>
							</Text>
							<Text
								as="h1"
								margin="12px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
								lg-margin="8px 0px 0px 0px"
							>
								Наши теннисные корты тщательно обслуживаются, чтобы обеспечить наилучшие условия для игры. Покрытие идеально подходит как для обычных игр, так и для соревновательных матчей.
							</Text>
						</Box>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
						<Icon
							display="block"
							category="md"
							icon={MdDone}
							size="20px"
							color="--primary"
							margin="2px 16px 0px 0px"
						/>
						<Box display="flex" flex-direction="column">
							<Text
								as="h1"
								margin="0px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
							>
								<Strong>
								Удобное расположение
								</Strong>
							</Text>
							<Text
								as="h1"
								margin="12px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
								lg-margin="8px 0px 0px 0px"
							>
								Легко добраться из разных частей города, наше местоположение позволяет вам легко попасть на корт и начать играть.
							</Text>
						</Box>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
						<Icon
							display="block"
							category="md"
							icon={MdDone}
							size="20px"
							color="--primary"
							margin="2px 16px 0px 0px"
						/>
						<Box display="flex" flex-direction="column">
							<Text
								as="h1"
								margin="0px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
							>
								<Strong>
								Доступные цены
								</Strong>
							</Text>
							<Text
								as="h1"
								margin="12px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
								lg-margin="8px 0px 0px 0px"
							>
								Мы предлагаем конкурентоспособные цены, гарантируя, что вы получите лучшее соотношение цены и качества.
							</Text>
						</Box>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
						<Icon
							display="block"
							category="md"
							icon={MdDone}
							size="20px"
							color="--primary"
							margin="2px 16px 0px 0px"
						/>
						<Box display="flex" flex-direction="column">
							<Text
								as="h1"
								margin="0px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
							>
								<Strong>
								Дружелюбный персонал
								</Strong>
							</Text>
							<Text
								as="h1"
								margin="12px 0px 0px 0px"
								font="--base"
								color="--dark"
								width="100%"
								md-width="100%"
								text-align="left"
								lg-margin="8px 0px 0px 0px"
							>
								Наша команда стремится обеспечить превосходное обслуживание клиентов, начиная с бронирования корта и заканчивая помощью в решении любых вопросов на месте.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://aventurinsky.com/img/2.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://aventurinsky.com/img/3.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Приходите и почувствуйте разницу в Aventurinsky Tennis Courts. Забронируйте корт сегодня и сделайте первый шаг к тому, чтобы стать чемпионом.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});